<template>
  <div class="rescueList">
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        :error.sync="loadError"
        error-text="加载失败，请稍后再试！"
        @load="onLoad"
      >
        <div v-for="(item, index) in list" :key="index" class="block">
          <div class="top">
            <div class="topleft">
              <div v-if="item.source == 1" class="sign leader">领导下发</div>
              <div v-else class="sign plan">预案下发</div>
              <div v-if="item.mesStatus == 0" class="dot"></div>
              <div class="code">{{ item.code || "--" }}</div>
            </div>
            <div class="time">{{ transferTime(item.issueTime) }}</div>
          </div>
          <div class="middle">
            <div class="item">
              <div class="itemlabel">事故地点：</div>
              <div
                v-if="item.accident.place"
                class="itemvalue"
                @click="showPlace(item.accident.place)"
              >
                {{ item.accident.place || "--" }}
              </div>
            </div>
            <div class="item">
              <div class="itemlabel">事发距离：</div>
              <div class="itemvalue">{{ transferDistance(item.distance) }}</div>
            </div>
            <img
              v-if="item.status == 1 && showStatus"
              src="../../../assets/images/djs.png"
              alt=""
              class="middleimg"
            />
            <img
              v-if="item.status == 2 && showStatus"
              src="../../../assets/images/ddz.png"
              alt=""
              class="middleimg"
            />
            <img
              v-if="item.status == 3 && showStatus"
              src="../../../assets/images/jyz.png"
              alt=""
              class="middleimg"
            />
            <img
              v-if="item.status == 5 && showStatus"
              src="../../../assets/images/ywc.png"
              alt=""
              class="middleimg"
            />
            <img
              v-if="item.status == 4 && showStatus"
              src="../../../assets/images/yqx.png"
              alt=""
              class="middleimg"
            />
          </div>
          <div class="content" @click="goDetail(item)">
            {{ item.duty || "--" }}
          </div>
          <div class="btncon">
            <div
              v-if="item.status == 1"
              :class="['btn', item.accident.status == 3 ? 'noClick' : '']"
              @click="update(item, 2)"
            >
              开始执行
            </div>
            <div
              v-if="item.status == 2"
              :class="['btn', item.accident.status == 3 ? 'noClick' : '']"
              @click="update(item, 3)"
            >
              开始救援
            </div>
            <div
              v-if="item.status == 3"
              :class="['btn', item.accident.status == 3 ? 'noClick' : '']"
              @click="update(item, 5)"
            >
              救援完成
            </div>
            <div
              v-if="item.status == 1 || item.status == 2 || item.status == 3"
              class="fenge"
            ></div>
            <div
              v-if="item.status == 1 || item.status == 2 || item.status == 3"
              :class="['btn', item.accident.status == 3 ? 'noClick' : '']"
              @click="update(item, 4)"
            >
              取消任务
            </div>
          </div>
        </div>
        <template #finished>
          <img
            v-if="list.length === 0 && finished"
            src="../../../assets/images/zwsj.png"
            alt="暂无数据"
            class="noData"
          />
          <div v-else>
            没有更多了
          </div>
        </template>
      </van-list>
    </van-pull-refresh>
  </div>
</template>

<script>
import api from "../api";
import { mapState } from "vuex";
import { Dialog } from "vant";
export default {
  name: "RescueList",
  data() {
    return {
      list: [],
      total: 0,
      showStatus: false,
      query: {
        page: 1,
        size: 10,
        issueId: "",
        status: "1,2,3,4,5",
        type: "1,2,3,6"
      },
      loading: false,
      finished: false,
      loadError: false,
      refreshing: false,
      supportLocation: true
    };
  },
  props: {
    type: {
      type: Number,
      default: () => 0
    }
  },
  watch: {
    type: {
      handler(newVal, oldVal) {
        this.list = [];
        this.query.status = newVal;
        this.query.page = 1;
        if (newVal == 0) {
          this.query.status = "1,2,3,4,5";
          this.showStatus = true;
        } else {
          this.showStatus = false;
        }
        this.getTaskList(this.query);
      },
      immediate: true
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.login.userInfo
    })
  },
  created() {
    this.query.issueId = this.userInfo.id;
  },
  methods: {
    //详情
    goDetail(data) {
      api.updateTask({
        id: data.id,
        mesStatus: 1,
        accidentId: data.accidentId
      });
      this.$router.push(
        `/rescueDetail/${encodeURIComponent(JSON.stringify(data))}`
      );
    },
    update(data, status1) {
      let that = this;
      if (data.accident.status == 3) {
        Dialog.alert({
          title: "",
          message: `该任务关联的事故已终止，无法进行后续操作！`
        });
        return;
      }
      if (!window.android || !window.android.getLocation) {
        this.supportLocation = false;
        let query = {
          id: data.id,
          status: status1,
          mesStatus: 1
        };
        that.updateTaskTrace([query]);
      } else {
        this.supportLocation = true;
        window.localGeoCallBack = async (location, time, status) => {
          if (status === "1") {
            let localArr = location.split(",");
            let query = {
              id: data.id,
              status: status1,
              mesStatus: 1,
              realGeo: {
                request2d: "POINT(" + localArr[0] + " " + localArr[1] + ")"
              }
            };
            await that.updateTaskTrace([query]);
          }
        };
        window.androidH5.getLocation("localGeoCallBack");
      }
    },
    //更新任务状态
    updateTaskTrace(data) {
      api.updateTaskTrace(data).then(res => {
        if (this.supportLocation) {
          Dialog.alert({
            title: "",
            message: `操作成功！`
          });
        } else {
          Dialog.alert({
            title: "操作成功！",
            message: `当前设备未开启定位请求，指挥部无法获取当前定位，请前往设置开启定位服务！`
          });
        }
        this.query.page = 1;
        this.$emit("setReadStatus");
        this.getTaskList(this.query);
      });
    },
    //展示地点弹框
    showPlace(data) {
      Dialog.alert({
        title: "事故地点",
        message: data,
        confirmButtonText: "确定"
      }).then(() => {});
    },
    //距离格式化
    transferDistance(data) {
      if (data) {
        let distance = parseInt(data);
        return (distance / 1000).toFixed(1) + "km";
      } else {
        return "--";
      }
    },
    //格式化时间
    transferTime(str) {
      if (str && str.length > 0) {
        let now = new Date(str).setHours(0, 0, 0, 0);
        let today = new Date().setHours(0, 0, 0, 0);
        //判断是否为当天
        if (now == today) {
          return str.substring(str.length - 5, str.length);
        } else {
          let year = new Date(str).getFullYear();
          let toYear = new Date().getFullYear();
          if (year == toYear) {
            //不是当天，是当年
            return str.substring(5, 10);
          } else {
            //不是当年
            return str.substring(0, 10);
          }
        }
      } else {
        return "--";
      }
    },
    onLoad() {
      this.getTaskList(this.query);
    },
    onRefresh() {
      this.query.page = 1;
      this.getTaskList(this.query);
    },
    getTaskList(data) {
      this.loading = true;
      this.finished = false;
      if (this.refreshing) {
        this.refreshing = false;
      }
      api
        .getTaskList(data)
        .then(res => {
          if (res.list) {
            this.loadError = false;
            this.loading = false;
            if (this.query.page == 1) {
              this.list = res.list;
            } else {
              this.list = this.list.concat(res.list);
            }
            if (this.list.length >= res.total) {
              this.finished = true;
            } else {
              this.query.page++;
            }
          } else {
            this.finished = true;
          }
        })
        .catch(() => {
          this.loading = false;
          this.finished = true;
          this.loadError = true;
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.rescueList {
  position: relative;
  padding: 10px;
  .block {
    border-radius: 6px;
    background: #ffffff;
    padding: 0 12px 12px;
    margin-bottom: 10px;
    .top {
      height: 38px;
      border-bottom: 1px solid #e7e8ea;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .topleft {
        display: flex;
        align-items: center;
        .sign {
          color: #ffffff;
          font-size: 12px;
          background: #3e8dfe;
          border-radius: 2px;
          padding: 2px;
        }
        .plan {
          background: #009e96;
        }
        .dot {
          width: 6px;
          height: 6px;
          background: #3e8dfe;
          border-radius: 50%;
          margin-left: 6px;
        }
        .code {
          font-weight: 600;
          font-size: 16px;
          color: #333743;
          margin-left: 6px;
        }
      }
      .time {
        font-size: 12px;
        color: rgba(100, 116, 144, 0.7);
      }
    }
    .middle {
      display: flex;
      justify-content: space-between;
      padding: 14px 0;
      padding-right: 60px;
      position: relative;
      .item {
        display: flex;
        font-size: 14px;
        color: rgba(100, 116, 144, 0.7);
        .itemlabel {
          width: 72px;
        }
        .itemvalue {
          max-width: 100px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
      .middleimg {
        position: absolute;
        right: 0;
        top: -22px;
        width: 56px;
      }
    }
    .content {
      background: rgba(65, 143, 253, 0.05);
      border-radius: 2px;
      padding: 6px 10px;
      line-height: 22px;
      color: #61656c;
      font-size: 12px;
    }
    .btncon {
      display: flex;
      justify-content: space-around;
      align-items: center;
      padding-top: 12px;
      .btn {
        font-size: 14px;
        color: #418ffd;
      }
      .noClick {
        color: #ccc;
      }
      .fenge {
        width: 1px;
        height: 18px;
        background: #e7e8ea;
      }
    }
  }
  .noData {
    width: 160px;
    margin-top: 100px;
  }
  .pop {
    width: 310px;
    height: 148px;
    background: #ffffff;
    border-radius: 16px;
  }
}
</style>
